let maxPubliftId = 0

const renderPublift = () => {
  const zones = document.querySelectorAll("[data-zone-publift]")
  if (zones.length == 0) return

  zones.forEach(zone => {
    let id = `publift-${zone.dataset.zonePublift}`

    if (document.getElementById(id)) {
      maxPubliftId += 1
      id = `${id}_${maxPubliftId}`
    }

    const div = document.createElement("div")
    div.id = id
    div.setAttribute("data-fuse", zone.dataset.zonePublift)
    zone.appendChild(div)
  })

  if (!window.fusetag) insertPublift()

  window.fusetag.que.push(() => {
    window.fusetag.pageInit()
  })
}

const insertPublift = () => {
  const script = document.createElement("script")
  script.async = true
  script.src = "https://cdn.fuseplatform.net/publift/tags/2/2107/fuse.js"
  document.head.appendChild(script)

  window.fusetag || (window.fusetag = {que: []})
}

export { renderPublift }
